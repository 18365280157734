






import { Component, Vue } from "vue-property-decorator";
import Page from "@/components/for-page-grid/Page.vue";
import XError from "@/components/hoc/Error.vue";

@Component({
  components: { Page, XError },
})
class PageNotFound extends Vue {
  message =
    "Возможно, введен не верный адрес в адресной строке или страница была удалена";
}

export default PageNotFound;
