
































import { Component, Prop, Vue } from "vue-property-decorator";
import XButton from "../SimpleButton.vue";

@Component({ components: { XButton } })
class Error extends Vue {
  @Prop({ required: true }) readonly code!: number;
  @Prop({ required: true }) readonly message!: string;

  @Prop({ default: false }) readonly useRedirectButton!: boolean;

  errors: { [errorCode: number]: string } = {
    404: "Страница не найдена",
    500: "Ошибка сервера",
  };

  public get title(): string {
    return this.errors[this.code];
  }
}

export default Error;
